.gif__form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 30px auto;
}

.gif__form-element {
    margin: 10px 0 !important;
}

.gif__input-output {
    display: flex;
}

.gif__input-output > * {
    width: 50%;
    text-align: center;
}